import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import BatteryConnect from '../components/batteryConnect/batteryConnect';
import Footer from '../components/batteryConnect/footer';

const BatterySolutionPage = () => (
  <Layout>
    <Seo title="バッテリーソリューション" />
    <BatteryConnect />
    <Footer />
  </Layout>
);

export default BatterySolutionPage;
