import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const Top = () => {
  let appDirection = 'column';
  let textPaddingRight = null;
  let titleMarginBottom = '1rem';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          textPaddingRight = '40px';
          titleMarginBottom = '0rem';
        } else {
          appDirection = 'column';
          textPaddingRight = null;
          titleMarginBottom = '1rem';
        }
        return (
          <>
            <Box style={{ marginBottom: '4rem' }}>
              <Box
                direction="row"
                justify="center"
                align="center"
                style={{ marginBottom: '1.5rem' }}
              >
                <StaticImage
                  objectFit="contain"
                  src="../../icons/linked_battery_charging_full_black.png"
                  formats={['auto', 'webp', 'avif']}
                  alt={'バッテリーConnect'}
                  width={40}
                  height={60}
                  imgClassName="ofi"
                />
                <h1 style={{ fontSize: 'min(2rem, 6vw)', marginBottom: '0rem' }}>
                  <span style={{ display: 'inline-block' }}>バッテリーConnect</span>
                </h1>
              </Box>
              <Box align="center">
                <Box align="center" style={{ marginBottom: '2rem' }}>
                  <h2
                    style={{
                      textAlign: 'center',
                      fontSize: 'min(2.5rem, 8vw)',
                      lineHeight: '3.5rem',
                      marginBottom: titleMarginBottom,
                    }}
                  >
                    <span style={{ display: 'inline-block' }}>カーボンニュートラル</span>
                    <span style={{ display: 'inline-block' }}>への入り口</span>
                  </h2>
                  <Box direction={appDirection} justify="center" align="center">
                    <Box direction="row" justify="center" align="center">
                      <a href="http://www.toyota-lf.com/">
                        <Box pad="medium">
                          <StaticImage
                            objectFit="contain"
                            src="../../icons/landf.png"
                            formats={['auto', 'webp', 'avif']}
                            alt={'TOYOTA L&F'}
                            width={100}
                            imgClassName="ofi"
                          />
                        </Box>
                      </a>
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '1.2rem',
                        }}
                      >
                        {'がご提供する'}
                      </span>
                    </Box>
                    <span
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2rem',
                      }}
                    >
                      {'リチウムイオンバッテリー（'}
                      <a
                        href="http://www.toyota-lf.com/products/detail/li_ion/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>車両連動タイプ</span>
                      </a>
                      <span>または</span>
                      <a
                        href="http://www.toyota-lf.com/products/detail/enelore/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>ENELORE</span>
                      </a>
                      {'）の'}
                    </span>
                    {/* <Box direction="row" justify="center" align="center"> */}
                    {/* <a href="http://www.toyota-lf.com/products/detail/enelore/">
                        <Box pad="medium">
                          <StaticImage
                            objectFit="contain"
                            src="../../images/ENELORE.png"
                            formats={['auto', 'webp', 'avif']}
                            alt={'ENELORE'}
                            width={100}
                            imgClassName="ofi"
                          />
                        </Box>
                      </a> */}
                    {/* <span style={{ textAlign: 'center', fontSize: '1.2rem' }}>{'の'}</span>
                    </Box> */}
                  </Box>
                  <span style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    {'エネルギー収支を見える化します'}
                  </span>
                </Box>
                <Box align="center">
                  <StaticImage
                    objectFit="contain"
                    src="../../images/batterySolution/batteryConnect/carbonNeutral.png"
                    formats={['auto', 'webp', 'avif']}
                    alt={'バッテリーConnect'}
                    imgClassName="ofi"
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
              </Box>
            </Box>
            <Box style={{ marginBottom: '2rem' }}>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>稼動状況</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      一つの画面でリチウム電池稼動ステータスの概況が分かります。
                    </span>
                    <span className="br"></span>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      SoC*低下、温度上昇、異常ステータスが検知されるとカードの色が変化していきます。
                    </span>
                    <span style={{ marginTop: '0.5rem' }}>{'*State of Charge (充電状態)'}</span>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/batterySolution/batteryConnect/batteryConnectMonitor.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'稼動状況'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>グラフ</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      {
                        'バッテリーID、期間でデータを選択し、SoC、温度、データをグラフにして可視化します。'
                      }
                    </span>
                  </Box>
                  <Box basis="1/2" flex="shrink" height={{ min: '100px' }} direction="row">
                    <StaticImage
                      objectFit="contain"
                      src="../../images/batterySolution/batteryConnect/batteryConnectGraph.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'グラフ'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box border="all" style={{ marginBottom: '1rem' }}>
                <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                  <Box
                    basis="1/2"
                    height={{ min: '100px' }}
                    style={{ paddingRight: textPaddingRight }}
                    justify="center"
                  >
                    <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>レポート</h3>
                    <span style={{ fontSize: '1.5rem', lineHeight: '1.8rem' }}>
                      {
                        'バッテリー毎の利用実績を日次集計します。総充放電量、電力消費、電気代などの観点で、傾向分析や課題の特定にご活用いただけます。'
                      }
                    </span>
                  </Box>
                  <Box
                    basis="1/2"
                    flex="shrink"
                    height={{ min: '100px' }}
                    direction="row"
                    justify="center"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/batterySolution/batteryConnect/batteryConnectDailyReport.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'日次レポート'}
                      imgClassName="ofi"
                      height={{ min: '100px' }}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Top;
