import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const Footer = () => {
  let appDirection = 'column';
  let pageMaxWidth = '80%';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          pageMaxWidth = '80%';
        } else {
          appDirection = 'column';
          pageMaxWidth = '100%';
        }
        return (
          <>
            <Box style={{ marginBottom: '2rem', maxWidth: pageMaxWidth, margin: '0 auto' }}>
              <Box direction="row" justify="center" align="center">
                <h2 style={{ fontSize: '2.5rem', lineHeight: '3.5rem', textAlign: 'center' }}>
                  バッテリーConnectについてもっと知る
                </h2>
              </Box>
              <Box
                direction={appDirection}
                style={{ marginBottom: '1.5rem', padding: '0 24px 0 24px' }}
              >
                <Box
                  height={{ min: '100px' }}
                  style={{ flexBasis: '40%' }}
                  align="center"
                  justify="center"
                >
                  <a
                    href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../icons/baseline_question_answer_black_48dp.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'お問い合わせ'}
                      width={250}
                      height={250}
                      imgClassName="ofi"
                      imgStyle={{ width: '100%' }}
                    />
                  </a>
                </Box>
                <Box style={{ flexBasis: '60%', height: '250px' }} justify="center">
                  <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                    お申込み、よくあるご質問、お問い合わせは｢
                    <a
                      href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>お問い合わせフォーム</span>
                    </a>
                    ｣にてご確認ください。
                  </span>
                </Box>
              </Box>
              <hr />
              <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                <Box
                  height={{ min: '100px' }}
                  style={{ flexBasis: '40%' }}
                  align="center"
                  justify="center"
                >
                  <a
                    href="http://www.toyota-lf.com/products/detail/enelore/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/batterySolution/batteryConnect/ENELORECatalog.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'カタログ'}
                      height={250}
                      imgClassName="ofi"
                      imgStyle={{ width: '100%' }}
                    />
                  </a>
                  <a
                    href="http://www.toyota-lf.com/products/detail/li_ion/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../images/batterySolution/batteryConnect/Step2LIB.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'Step2 LIB'}
                      height={200}
                      imgClassName="ofi"
                      imgStyle={{ width: '100%' }}
                    />
                  </a>
                </Box>
                <Box style={{ flexBasis: '60%', height: '250px' }} justify="center">
                  <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                    リチウムイオンバッテリー（
                    <a
                      href="http://www.toyota-lf.com/products/detail/li_ion/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>車両連動タイプ</span>
                    </a>
                    <span>または</span>
                    <a
                      href="http://www.toyota-lf.com/products/detail/enelore/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>ENELORE</span>
                    </a>
                    ）の詳細情報をご確認いただけます。
                  </span>
                </Box>
              </Box>
              <hr />
              <Box pad="medium" direction={appDirection} style={{ marginBottom: '1.5rem' }}>
                <Box
                  pad="medium"
                  height={{ min: '100px' }}
                  style={{ flexBasis: '40%' }}
                  align="center"
                  justify="center"
                >
                  <a
                    href="https://www.youtube.com/channel/UC0DEZelqP60dK8vEWDqy_yg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      objectFit="contain"
                      src="../../icons/youtube.png"
                      formats={['auto', 'webp', 'avif']}
                      alt={'YouTube'}
                      width={460}
                      height={150}
                      imgClassName="ofi"
                      imgStyle={{ width: '100%' }}
                    />
                  </a>
                </Box>
                <Box style={{ flexBasis: '60%', height: '250px' }} justify="center">
                  <span style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
                    <a
                      href="https://www.youtube.com/channel/UC0DEZelqP60dK8vEWDqy_yg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>公式チャンネル</span>
                    </a>
                    にて機能紹介、操作説明動画を公開しています。
                  </span>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Footer;
