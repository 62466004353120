import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';

const Price = () => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        return (
          <>
            <Box border="all" style={{ marginBottom: '3rem' }}>
              <Box
                pad={{ top: '60px', right: 'medium', bottom: '60px', left: 'medium' }}
                style={{ marginBottom: '1.5rem' }}
              >
                <h3 style={{ fontSize: '2.5rem', lineHeight: '3.5rem' }}>＜ご利用料金＞</h3>
                <Box></Box>
                <span style={{ fontSize: '1.5rem', lineHeight: '2.2rem', marginBottom: '0.5rem' }}>
                  <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {'永年無料'}
                  </span>
                  {'（ENELORE、リチウムイオンバッテリー車両連動タイプご利用のお客様に限ります。）'}
                </span>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Price;
