import * as React from 'react';
import Top from './top';
import Price from './price';

const BatteryConnect = () => {
  return (
    <>
      <Top />
      <Price />
    </>
  );
};

export default BatteryConnect;
